import React from 'react'
import Header from './header'
import {Link, StaticQuery, graphql} from 'gatsby'
import '../styles/layout.css'
import styles from './layout.module.css'
import {FaLinkedin, FaResearchgate, FaFacebookSquare, FaTwitter, FaFileAlt} from 'react-icons/fa'
import {MdSchool} from 'react-icons/md'
import styled from 'styled-components'
import GotoMeet from '../gotomeet.svg'
import {imageUrlFor} from '../lib/image-url'
import {buildImageObj} from '../lib/helpers'

const GotoMeetLogo = styled.img`
  height:32px;
  width: 32px;
`
const Image = styled.img`
  height:48px;
  @media (max-width: 800px){
  height:32px;
  }
`
const Copyright = styled.div`
  min-width:100px;
  @media (max-width: 800px){
  min-width:100%;
  margin-bottom: 1rem;
  }
`
const Wrapper = styled.div`
  @media (max-width: 800px){
    flex-direction: column;
  }
`
const FooterNav = styled.ul`
width:100%;
padding: 0;
margin:0;
display:grid;
grid-auto-columns: auto;
grid-template-columns:repeat(${props => props.length}, auto);
justify-content:flex-end;
align-items:center;
grid-column-gap:1rem;
li{
  display:flex;
  justify-content:center;
}  
@media (max-width: 800px){
  grid-template-columns:repeat(auto-fill, 100px);
  justify-content:center;
  li{
    padding-bottom: 1rem;
  }  
}
`

const getIconFromPlatform = (platform) => {
  switch (platform) {
    case 'LinkedIn':
      return <FaLinkedin size={22} />
    case 'Researchgate':
      return <FaResearchgate size={22} />
    case 'Twitter':
      return <FaTwitter size={22} />
    case 'Facebook':
      return <FaFacebookSquare size={22} />
    case 'GoogleScholar':
      return <MdSchool size={22} />
    case 'GotoMeet':
      return <GotoMeetLogo src={GotoMeet} />
  }
}
const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, data}) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <Wrapper className={styles.footerWrapper}>
        <Copyright className={styles.siteInfo}>
          © IGNIS {new Date().getFullYear()}
        </Copyright>
        <FooterNav length={data.sanitySiteSettings.footer.length + data.sanitySiteSettings.tags.length + 1}>
          {data.sanitySiteSettings.footer[0] && data.sanitySiteSettings.footer.map((image) => (
            <li><a href={image.url} target='_blank'><Image
              src={imageUrlFor(buildImageObj(image))
                .width(200)
                .fit('crop')
                .url()}
              alt={image}
            /></a></li>
          ))}
          {data.sanitySiteSettings.tags[0] &&
            data.sanitySiteSettings.tags.map(social => (
              <li><a href={social.url} target='_blank'>{getIconFromPlatform(social.platform[0])}</a></li>
            ))
          }
          <li>
            <Link to='/impressum/'>Impressum & Datenschutz</Link>
          </li>
        </FooterNav>
      </Wrapper>
    </footer>
  </>
)
export default props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery{
        sanitySiteSettings{
          footer{
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            _key
            _type
            url
            alt
          }
          tags {
            _key
            _type
            name
            url
            platform
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)
