import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import styles from './header.module.css'
import logo from '../ignis-logo.svg'
import styled from 'styled-components'

const StyledLogo = styled.img`
  height:70px;
  width: 150px;
  -webkit-backface-visibility: hidden;
`
const StyledNav = styled.nav`
  @media (max-width:450px){
  border-bottom: 1px solid var(--color-black);
}
`

const Header = ({onHideNav, onShowNav, showNav, siteTitle}) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to='/'>
          <StyledLogo src={logo} />
        </Link>
      </div>

      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol='hamburger' />
      </button>

      <StyledNav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
            <Link to='/business-units/'>Business Units</Link>
          </li>
          <li>
            <Link to='/references/'>References</Link>
          </li>
          <li>
            <Link to='/team/'>Team</Link>
          </li>
          <li>
            <Link to='/contact/'>Contact</Link>
          </li>
        </ul>
      </StyledNav>
    </div>
  </div>
)

export default Header
