import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes, filterOutDocsWithoutSlugs, buildImageObj} from '../lib/helpers'
import BlockContent from '../components/block-content'
import {FaLinkedin, FaResearchgate, FaFacebookSquare, FaTwitter, FaFileAlt} from 'react-icons/fa'
import {MdSchool} from 'react-icons/md'
import GotoMeet from '../gotomeet.svg'

import {imageUrlFor} from '../lib/image-url'
import Fade from 'react-reveal/Fade'

import {responsiveTitle1} from '../components/typography.module.css'
import styled from 'styled-components'

const Image = styled.img`
width: 100%;
@media (max-width:600px) {
}`
const GotoMeetLogo = styled.img`
  height:32px;
  width: 32px;
`
const Mail = styled.a`
  color: white;
  text-decoration: none;
  font-size: var(--font-title2-size);
  :hover{
    color: var(--color-accent);
  }
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 2em;
  ul{
    padding:0;
    display:flex;
  }
  li{
    padding-right:1rem;
      font-size: var(--font-title4-size);
  }
  ul li a{
    display: flex;
    align-items: center;
  }
  >div{
    grid-area: text;
    display: flex;
    flex-direction: column;
  }
  >img{
    grid-area: image;
  }
    >div>div{
      margin-top: auto; 
    }
    >div>div>p{
      margin: 0.2em 0; 
    }
    grid-template-areas: 
    "image text";
  @media (max-width:600px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 1em;
    grid-template-areas: 
    "text" 
    "image";
    >img{
      transform:translateX(40px)translateY(-300px);
      z-index:0;
      opacity:0.7;
    }
    >div{
      z-index:1;
      min-height: 40vh;
    }
    >div>div{
      margin-top: auto;
    }

  }
  `
export const query = graphql`
  query contactQuery {
  sanityContact {
    id
    title
    mainImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
      alt
    }
    _rawText
    _rawAdress
    email
    tags{
      platform
      url
      name
    }
  }
  }
`
const getIconFromPlatform = (platform) => {
  switch (platform) {
    case 'LinkedIn':
      return <FaLinkedin size={28} />
    case 'Researchgate':
      return <FaResearchgate size={28} />
    case 'Twitter':
      return <FaFacebookSquare size={28} />
    case 'Facebook':
      return <FaTwitter size={28} />
    case 'GoogleScholar':
      return <MdSchool size={28} />
    case 'GotoMeet':
      return <GotoMeetLogo src={GotoMeet} />
  }
}
const Contact = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  console.log(data.sanityContact.tags)
  return (
    <Layout>
      <SEO title='Contact' />
      <Container>
        <Fade>
          <Wrapper>
            <Image
              src={imageUrlFor(buildImageObj(data.sanityContact.mainImage))
                .width(400)
                .height(600)
                .fit('crop')
                .url()}
              alt={data.sanityContact.mainImage.alt}
            />
            <div>
              <h1 className={responsiveTitle1}>{data.sanityContact.title}.</h1>
              {data.sanityContact._rawText && <BlockContent blocks={data.sanityContact._rawText || []} />}
              <Mail href={`mailto:${data.sanityContact.email}?subject=Business Inquiry IGNIS`}>{data.sanityContact.email}</Mail>
              <ul>
                {data.sanityContact.tags[0] &&
                  data.sanityContact.tags.map(social => (
                    <li><a href={social.url} target='_blank'>{getIconFromPlatform(social.platform[0])}{social.name}</a></li>
                  ))
                }
              </ul>
              {data.sanityContact._rawAdress && <BlockContent blocks={data.sanityContact._rawAdress || []} />}
            </div>
          </Wrapper>
        </Fade>
      </Container>
    </Layout>
  )
}

export default Contact
